/** @format */

import deApi from "@locales/de/api.json";
import deApparatus from "@locales/de/apparatus.json";
import deCostcenter from "@locales/de/costcenter.json";
import deGlobalTranslation from "@locales/de/global.json";
import deInspection from "@locales/de/inspection.json";
import dePaymentTranslation from "@locales/de/payment.json";
import deTranslation from "@locales/de/translation.json";
import deUser from "@locales/de/user.json";
import enApi from "@locales/en/api.json";
import enApparatus from "@locales/en/apparatus.json";
import enCostcenter from "@locales/en/costcenter.json";
import enGlobalTranslation from "@locales/en/global.json";
import enInspection from "@locales/en/inspection.json";
import enPaymentTranslation from "@locales/en/payment.json";
import enTranslation from "@locales/en/translation.json";
import enUser from "@locales/en/user.json";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

void i18n
    .use(initReactI18next)

    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        lng: "de",
        // lng: Localization.locale,
        fallbackLng: "de",
        debug: false,
        saveMissing: false, // send not translated keys to endpoint
        keySeparator: ".", // we do not use keys in form messages.welcome
        initImmediate: false,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        saveMissingPlurals: true,
        pluralSeparator: "_",
        preload: ["de", "en"],
        resources: {
            en: {
                api: {
                    ...enApi,
                },
                apparatus: {
                    ...enApparatus,
                },
                costcenter: {
                    ...enCostcenter,
                },
                global: {
                    ...enGlobalTranslation,
                },
                inspection: {
                    ...enInspection,
                },
                payment: {
                    ...enPaymentTranslation,
                },
                translation: {
                    ...enTranslation,
                },
                user: {
                    ...enUser,
                },
            },
            de: {
                api: {
                    ...deApi,
                },
                apparatus: {
                    ...deApparatus,
                },
                costcenter: {
                    ...deCostcenter,
                },
                global: {
                    ...deGlobalTranslation,
                },
                inspection: {
                    ...deInspection,
                },
                payment: {
                    ...dePaymentTranslation,
                },
                translation: {
                    ...deTranslation,
                },
                user: {
                    ...deUser,
                },
            },
        },
        react: {
            useSuspense: false,
        },
    });

export default i18n;
